import React from 'react';
import ProfilePicture from '../../../../assets/mountain_1_600x600.png';
import {Tag} from '../../../../components'
import './Profile.scss';

const tags = [
    {
        title: 'FREELANCING',
        tagText: 'AVAILABLE'
    },
    {
        title: 'BRAINSTORMING',
        tagText: 'AVAILABLE'
    }
]

const Profile = () => (
    <div className={'profile-container'}>
        <div className={'image-container'}>
            <img alt="photo_clone_zack" className="img-responsive" src={ProfilePicture} />
        </div>
        {
            tags.map(
                ({title, tagText}) => (
                    <div className={'tag-container'} key={title}>
                        <span className={'text'}>{`${title}:`}</span>
                        <Tag text={tagText}/>
                    </div>
                )
            )
        }
    </div>
)

export default Profile;