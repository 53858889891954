import React, {Component} from 'react';
import emailjs from 'emailjs-com';
import './HireMe.scss';

class HireMe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            toName: 'Zachary Langley',
            fromName: '',
            fromEmail: '',
            fromPhoneNumber: '',
            subject: '',
            message: '',
            error: false,
            requestError: false,
            success: false
        }
    }

    sendEmail = (e) => {
        e.preventDefault();
        const { 
            toName, 
            fromName, 
            fromEmail, 
            fromPhoneNumber, 
            subject, 
            message
        } = this.state
        if(this.validateForm()) {
            const REACT_APP_EMAIL_API_KEY = process.env.REACT_APP_EMAIL_API_KEY
            const data = {
                "from_name": fromName,
                "to_name": toName,
                "subject": subject,
                "message": message,
                "from_phone_number": fromPhoneNumber,
                "from_email": fromEmail
            }
            emailjs.send('default_service', 'template_business', data, REACT_APP_EMAIL_API_KEY)
            .then((result) => {
                this.setState({ 
                    requestError: false,
                    error: false,
                    success: true,
                    toName: '', 
                    fromName: '', 
                    fromEmail: '', 
                    fromPhoneNumber: '', 
                    subject: '', 
                    message: ''
                })
            }, (error) => {
                this.setState({ requestError: true, error: false, success: false })
            });
        } else {
            this.setState({ requestError: false, error: true, success: false })
        }
    }

    validateForm = () => {
        const { 
            fromName, 
            fromEmail, 
            message
        } = this.state
        return (fromName || fromEmail || message)
    }

    onFromNameChange = (e) => {
        if(e.target.value) {
            this.setState({ fromName: e.target.value })
        } else {
            this.setState({ fromName: '' })
        }
    }
    
    onFromPhoneNumberChange = (e) => {
        if(e.target.value) {
            this.setState({ fromPhoneNumber: e.target.value })
        } else {
            this.setState({ fromPhoneNumber: '' })
        }
    }

    onFromEmailChange = (e) => {
        if(e.target.value) {
            this.setState({ fromEmail: e.target.value })
        } else {
            this.setState({ fromEmail: '' })
        }
    }

    onSubjectChange = (e) => {
        if(e.target.value) {
            this.setState({ subject: e.target.value })
        } else {
            this.setState({ subject: '' })
        }
    }

    onMessageChange = (e) => {
        if(e.target.value) {
            this.setState({ message: e.target.value })
        } else {
            this.setState({ message: '' })
        }
    }

    render() {
        const { error, success, requestError } = this.state;
        const errorClassName = (error) ? 'error' : ''
        return (
            <div className={'hire-me-container'}>
                <div className={'header'}>
                    <div className={'title'}>{'Hire me with the form below.'}</div>
                    <div className={'subtitle'}>{'Feel free to keep it casual, we aren\'t building rockets...unless we are.'}</div>
                    {
                        requestError && <div className={'request-error'}>An error occured with the request, please try again.</div>
                    }
                    {
                        success && <div className={'request-success'}>Email has been sent. I usually reply within 24 hours</div>
                    }
                </div>
                <form id="myform" onSubmit={this.sendEmail}>
                    <label className={errorClassName}>
                        Name
                        <span style={{ color: 'red', marginLeft: 2 }}>
                            * {error ? '(Required)' : ''}
                        </span>
                    </label>
                    <input type="text" name="from_name" onChange={this.onFromNameChange} />

                    <label className={errorClassName}>
                        Email
                        <span style={{ color: 'red', marginLeft: 2 }}>
                            * {error ? '(Required)' : ''}
                        </span>
                    </label>
                    <input type="text" name="from_email" onChange={this.onFromEmailChange}/>

                    <label>Phone Number (Optional)</label>
                    <input type="text" name="from_phone_number" onChange={this.onFromPhoneNumberChange}/>

                    <label>Subject (Optional)</label>
                    <input type="text" name="subject" onChange={this.onSubjectChange}/>

                    <label className={errorClassName}>
                        Message
                        <span style={{ color: 'red', marginLeft: 2 }}>
                            * {error ? '(Required)' : ''}
                        </span>
                    </label>
                    <textarea type="text" name="message" onChange={this.onMessageChange}/>

                    <button className={'btn'}>SEND</button>
                </form>
            </div>
        )
    }
}

export default HireMe;