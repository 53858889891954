import React from 'react';
import { Layout } from './components';
import './App.scss';
import {Home, HireMe} from './screens';
import {Header, Logo, SocialMediaIcons} from './components';
import { Route, Switch } from 'react-router-dom';

const getHeader = () => {
  return (
    <Header
      left={<Logo />}
      right={<SocialMediaIcons />}
    />
  )
}

const Error = () => (
  <h1>Oops! You've stumbled into unknown territory. You see enemies approaching. Roll Initiative!</h1>
)

const Router = () => (
  <Switch>
    <Route exact path={'/'} component={Home}/>
    <Route exact path={'/hire-me'} component={HireMe}/>
    <Route component={Error} />
  </Switch>
)

function App() { 
  return (
    <div className="App">
      <Layout 
        top={getHeader()}
        bottom={Router()}
      />
    </div>
  );
}

export default App;
