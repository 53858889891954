import React from 'react';
import computerIcon from '../../../../assets/computer.png';
import coffeeIcon from '../../../../assets/coffee_cup.png'
import './WelcomeMessage.scss';

const WelcomeMessage = () => (
    <div className={'welcome-message-container'}>
        <div className={'image'}>
            <img src={computerIcon} alt={'not_real_computer'}/>
            <img src={coffeeIcon} alt={'not_real_coffee'} style={{ marginBottom: '5px', marginLeft: '2px' }}/>
        </div>
        <div className={'title'}>
            <span className={'title-upper'}>{'Zachary Langley'}</span>
        </div>
        <div className={'subtitle'}>{'Dungeon Master / Software Developer'}</div>
    </div>
)

export default WelcomeMessage