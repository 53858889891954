import React from 'react';
import './Card.scss';

const Card = ({left, top, bottom, content}) => (
    <div className={'card-container'}>
        {
            left &&
            <div className={'card-side'}>
                {left}
            </div>
        }
        <div className={'card-body'}>
            {
                top &&
                <div className={'card-top'}>
                    {top}
                </div>
            }
            <div className={'card-bottom'}>
                {bottom || content}
            </div>
        </div>
    </div>
)

export default Card;