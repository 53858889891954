import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'
import { withRouter } from 'react-router-dom'

import './Logo.scss';

const logoText = 'ZACK'

const Logo = (props) => {
    const {history} = props
    return (
        <div className={'logo-container'}>
            <div className={'logo-text'} onClick={() => history.push('/')}>{logoText}</div>
            <FontAwesomeIcon 
                style={
                    {
                        height: '27px',
                        width: '27px',
                        padding: 0
                    }
                } 
                icon={faCoffee}
            />
        </div>
    )
}

export default withRouter(Logo);