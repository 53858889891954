import React from 'react';
import { withRouter } from 'react-router-dom'
import './Options.scss';
import resumePDF from '../../../../assets/Resume2020.pdf';

const onDownloadClick = () => {
    window.open(resumePDF)
}

const onHireMeClick = (history) => {
    history.push('/hire-me')
}

const Options = (props) => {
    const { history } = props;
    return (
        <div className={'options-container'}>
            <button className={'btn'} onClick={onDownloadClick}>{'DOWNLOAD RESUME'}</button>
            <button className={'btn'} onClick={() => onHireMeClick(history)}>{'HIRE ME'}</button>
        </div>
    )
}

export default withRouter(Options);