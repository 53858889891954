import React from 'react';
import './SocialMediaIcons.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faFacebook,
    faGithub,
    faLinkedin
} from '@fortawesome/free-brands-svg-icons'

const IconStyle = {
    height: '25px',
    width: '25px'
}

const SocialLinks = {
    facebook: 'https://www.facebook.com/Zachary-Langley-100111528449211/',
    github: 'https://github.com/ZacharyLangley',
    linkedin: 'https://www.linkedin.com/in/z-langley/'
}

const SocialMediaIcons = () => (
    <div className={'social-media-icons-container'}>
        <a className={'social-item facebook'} href={SocialLinks.facebook} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={IconStyle} icon={faFacebook}/>
        </a>
        <a className={'social-item github'} href={SocialLinks.github} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={IconStyle} icon={faGithub}/>
        </a>
        <a className={'social-item linkedin'} href={SocialLinks.linkedin} target="_blank" rel="noopener noreferrer">
            <FontAwesomeIcon style={IconStyle} icon={faLinkedin}/>
        </a>
    </div>
)

export default SocialMediaIcons;