import React from 'react';
import './Header.scss';

const Header = ({ left, right }) => (
    <div className={'header-container'}>
        <div className={'header-left'}>
            {left}
        </div>
        <div className={'header-right'}>
            {right}
        </div>
    </div>
)

export default Header;