import React from 'react';
import './Layout.scss';

const Layout = ({top, bottom}) => (
    <div className={'layout-container'}>
        <div className={'layout-top'}>{top}</div>
        <div className={'layout-bottom'}>{bottom}</div>
    </div>
)

export default Layout