import React from 'react';
import './Contact.scss';

const contactInfo = [
    {
        category: 'AGE',
        information: '25'
    },
    {
        category: 'HOBBIES',
        information: 'Dungeon Mastering, Hiking, Skateboarding, Pushing On'
    },
    {
        category: 'PET',
        information: 'Lab Mix'
    },
    {
        category: 'E-MAIL',
        information: 'ZacLangley04@gmail.com'
    },
    {
        category: 'PHONE',
        information: '(469) 810 - 1970'
    },
]

const Contact = () => (
    <div className={'contact-container'}>
        {
            contactInfo.map(
                ({ category, information }) => (
                    <div className={'contact-item'} key={category}>
                        <div className={'contact-category'}>{category}</div>
                        <div className={'contact-information'}>{information}</div>
                    </div>
                )
            )
        }
    </div>
)

export default Contact;