import React from 'react';
import './Home.scss';
import { Card } from '../../components';
import { Profile, WelcomeMessage, Contact, Options } from './components';

const Home = () => (
    <div className={'home-container'}>
        <Card 
            left={<Profile />}
            top={<WelcomeMessage />}
            bottom={<Contact />}
        />
        <Options />
    </div>
)

export default Home;